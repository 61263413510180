.grocery-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grocery-list-container input,
.grocery-list-container button {
  margin: 5px;
}

.grocery-list-container ul {
  list-style-type: none;
  padding: 0;
}

.grocery-list-container li {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 5px;
}
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  background-color:darkgray;
}
button {
  margin-top: 10px;
  margin-bottom: 10px;
}